<script>
import store from '@/infrastructure/store'
import BirthdayPickerCard from '@/features/users/components/BirthdayPickerCard'

export default {
  components: {
    BirthdayPickerCard,
  },

  computed: {
    authUser: () => store.state.auth.authUser,
    authUserRoles: () => store.state.auth.authUserRoles,
  },
}
</script>

<template>
  <div>
    <v-card>
      <v-card-text>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <h2 class="mx-auto">{{ authUser.name }}</h2>
            <p>{{ authUser.email }}</p>

            <div v-if="authUserRoles && authUserRoles.length > 0">
              <div class="text-h6 mb-1">Your roles</div>
              <v-chip v-for="role in authUserRoles" :key="role" color="success">{{ role }}</v-chip>
            </div>
          </div>

          <div>
            <v-img :src="authUser.picture" width="50" alt="Profile picture" />
          </div>
        </div>
      </v-card-text>
    </v-card>

    <div class="pt-3"></div>

    <BirthdayPickerCard />

    <div class="pt-3"></div>
  </div>
</template>
